import { render, staticRenderFns } from "./main_intro.vue?vue&type=template&id=4526f2da&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./main_intro.vue?vue&type=script&lang=js&"
export * from "./main_intro.vue?vue&type=script&lang=js&"
import style0 from "./main_intro.vue?vue&type=style&index=0&id=4526f2da&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4526f2da",
  null
  
)

export default component.exports